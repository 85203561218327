import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import ReactDOMServer from "react-dom/server";
import moment from "moment/moment";
import {useDispatch, useSelector} from 'react-redux'

import mobiscroll from "@mobiscroll/react";

import {CallToActionButton, TimeSlotSelector} from '../components';

import {
    validateField,
    requiredCheck,
    minlengthCheck,
    exactLengthCheck,
    luhnNumberCheck,
    invalidTelephoneCheck
} from '../utils/validators';
import {makeBooking, updateAvailableBookings} from "../redux/actions/bookings";
import {normalizeTelephoneNumber} from "../utils/phone-number";


const renderSelectItem = (row_1, row_2) => (
    <React.Fragment>
        <div style={{fontSize: 16, lineHeight: '18px'}}>{row_1}</div>
        <div style={{fontSize: 10, lineHeight: '18px'}}>{row_2}</div>
    </React.Fragment>
);

const clinicList = [
    {
        value: 1,
        text: 'Rosebank, Johannesburg',
        address: 'Standard Bank Wellbeing Centre, 2nd Floor East, 30 Baker Street',
        address_gps: '30 Baker Street, Rosebank, Johannesburg',
        html: ReactDOMServer.renderToStaticMarkup(renderSelectItem('Rosebank, Johannesburg', 'Standard Bank Wellbeing Centre, 2nd Floor East, 30 Baker Street'))
    }
];


const BookingScreen = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateAvailableBookings())
    }, []);

    const DEFAULT_FORM_VALUES = {
        fullName: '',
        cellphone: '',
        id_number: '',
        location: 1,
        selectedTimeSlot: ''
    };

    const [formErrors, setFormErrors] = useState({});
    const [formValues, setFormValues] = useState(Object.assign({}, DEFAULT_FORM_VALUES));

    const [selectingTimeSlot, setSelectingTimeSlot] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
    const [termsAccepted, setTermsAccepted] = useState(false);

    const bookingRequestPending = useSelector(state => state.booking.booking_request_pending);

    // const success = useRef();
    const locationSelect = useRef();

    const onFormChange = (key, event) => {
        const valueUpd = Object.assign({}, formValues);
        valueUpd[key] = event.target.value;
        const errorUpd = validateField(key, event.target.value, validationSettings);

        setFormValues(valueUpd);
        setFormErrors(errorUpd);
    };

    const validationSettings = {
        fullName: [requiredCheck, (value) => minlengthCheck(2, value)],
        cellphone: [requiredCheck, invalidTelephoneCheck],
        id_number: [requiredCheck, (value) => exactLengthCheck(13, value), luhnNumberCheck],
        location: [requiredCheck],
        selectedTimeSlot: [requiredCheck],
    };

    const validateAllFields = () => {
        const errorsUpd = Object.assign({}, formErrors);
        const values = Object.assign({}, formValues);
        let allValid = true;
        for (let key in values) {
            errorsUpd[key] = validateField(key, values[key], validationSettings)[key];
            allValid = allValid && !errorsUpd[key];
        }

        if (allValid) {
            // success.current.instance.show();
            dispatch({
                type: 'SET_BOOKING',
                payload: {...values, location: clinicList.find((e) => e.id === values.value)}
            });

            let person = {
                'name': formValues['fullName'],
                'contact_number': normalizeTelephoneNumber(formValues['cellphone']),
                'unique_identifier': `SAID_${formValues['id_number']}`
            };

            dispatch(makeBooking(person, selectedTimeSlot));
        }

        setFormErrors(errorsUpd);
    };

    const onTimeSlotSelected = (event) => {
        setSelectedTimeSlot(event.meta);
        setSelectingTimeSlot(false);
        onFormChange("selectedTimeSlot", {target: {value: event.start}})
    };

    const onTermsToggled = (e) => {
        setTermsAccepted(e.target.checked);
    };

    const renderForm = () => (
        <Page>
            <AppHeaderParent>
                <AppHeader className={`app-header`}>
                    <p>Make an Appointment</p>
                </AppHeader>
            </AppHeaderParent>

            <PageContents>
                <Form inputStyle="underline" labelStyle="floating" noValidate={true}>
                    <mobiscroll.FormGroup>
                        <mobiscroll.FormGroupTitle>Your details</mobiscroll.FormGroupTitle>
                        <mobiscroll.Input
                            value={formValues.fullName}
                            onChange={(value) => onFormChange("fullName", value)}
                            valid={!formErrors.fullName}
                            errorMessage={formErrors.fullName}
                            placeholder="Full Name"
                            icon="user4"/>


                        <mobiscroll.Input
                            value={formValues.cellphone}
                            onChange={(value) => onFormChange("cellphone", value)}
                            valid={!formErrors.cellphone}
                            errorMessage={formErrors.cellphone}
                            placeholder="Cellphone Number"
                            icon="phone"/>

                        <mobiscroll.Input
                            value={formValues.id_number}
                            onChange={(value) => onFormChange("id_number", value)}
                            valid={!formErrors.id_number}
                            errorMessage={formErrors.id_number}
                            placeholder="ID Number"
                            icon="material-search"/>
                    </mobiscroll.FormGroup>
                    <mobiscroll.FormGroup>
                        <mobiscroll.FormGroupTitle>Your Appointment</mobiscroll.FormGroupTitle>

                        <label>
                            Location
                            <mobiscroll.Select
                                ref={locationSelect}
                                display="bottom"
                                touchUi={true}
                                multiline={2}
                                height={50}
                                filter={true}
                                data={clinicList}
                                value={formValues.location}
                                valid={!formErrors.location}
                                errorMessage={formErrors.location}
                                onSet={(event, inst) => onFormChange("location", {target: {value: inst.getVal()}})}
                            />
                        </label>

                        <mobiscroll.Input
                            onClick={() => setSelectingTimeSlot(true)}
                            value={formValues.selectedTimeSlot ? moment(formValues.selectedTimeSlot).format('MMMM Do YYYY, h:mm:ss a') : ''}
                            valid={!formErrors.selectedTimeSlot}
                            // errorMessage={formErrors.selectedTimeSlot}
                            readOnly
                            placeholder="Date and Time"
                            icon="calendar"/>

                        <mobiscroll.Checkbox value="snack" checked={termsAccepted} onChange={onTermsToggled}>
                            <span className="mbsc-label"> Accept terms and conditions</span>
                        </mobiscroll.Checkbox>

                        <div className="mbsc-padding">
                            <a href="terms_and_conditions.pdf" target="_blank"> <span className="mbsc-desc">Click to view terms</span></a>
                        </div>

                    </mobiscroll.FormGroup>


                </Form>

                <mobiscroll.FormGroup className="mbsc-btn-group-block">
                    <CallToActionButton onClick={validateAllFields} icon="pencil" color="success"
                                        disabled={bookingRequestPending || (!termsAccepted)}
                    >Book</CallToActionButton>
                </mobiscroll.FormGroup>

                {/*<mobiscroll.Popup*/}
                {/*ref={success}*/}
                {/*focusOnClose={false}*/}
                {/*onSet={() => dispatch(push('/'))}*/}
                {/*buttons={[{*/}
                {/*text: 'Okay',*/}
                {/*handler: 'set'*/}
                {/*}]}*/}
                {/*>*/}
                {/*<h3>Thank you for your appointment</h3>*/}
                {/*<p>You will receive an SMS confirmation of your appointment</p>*/}
                {/*</mobiscroll.Popup>*/}
            </PageContents>
        </Page>
    );

    const renderTimeSlotSelector = () => (
        <TimeSlotSelector
            onCancel={() => setSelectingTimeSlot(false)}
            onEventSelected={(event) => onTimeSlotSelected(event)}
        />
    );

    return selectingTimeSlot ? renderTimeSlotSelector() : renderForm()

};

const Page = styled.div`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 42px 1fr;
        width: 100%;
        height: 100%;
    `;

const PageContents = styled(mobiscroll.Page)`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
  
`;

const AppHeaderParent = styled(mobiscroll.Page)`
    &.mbsc-ios .app-header {
      border-bottom: 1px solid #ccc;
      background: #f7f7f7;
      color: #000;
    }

    &.mbsc-material .app-header {
      //background: #009688;
      background: #2E3466;
      color: #fff;
    }
`;

const AppHeader = styled.div`
    z-index: 10;
    height: 42px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 0;
    
    p {
      padding: 0;
      margin: 0;
      font-size: 20px;
    }    
`;

const Form = styled(mobiscroll.Form)`
    grid-row: 1;
    grid-column: 1;
`;


export default BookingScreen;