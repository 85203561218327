import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import mobiscroll from "@mobiscroll/react";
import moment from "moment/moment";
import randomMC from "random-material-color";

// import generateSlots from '../utils/time-slot-generator';


const now = new Date();

const TimeSlotSelector = (props) => {

    const [events, setEvents] = useState([]);

    const timeSlotsAvailable = useSelector(state => state.booking.available_bookings);
    const loading = useSelector(state => state.booking.loading);

    const onEventSelect = (event, inst) => {
        props.onEventSelected(event.event);
    };


    useEffect(() => {

        let events = [];

        if (timeSlotsAvailable.available_slots !== undefined) {
            timeSlotsAvailable.available_slots.forEach((slot) => {
                events.push({
                    "start": moment(slot['start_time']),
                    "end": moment(slot['end_time']),
                    "text": "Consult available",
                    "color": randomMC.getColor(),
                    "meta": slot
                })
            })
        }

        setEvents(events)
    }, [timeSlotsAvailable]);

    return (
        <Page>
            <mobiscroll.Eventcalendar
                display="inline"
                onCancel={() => props.onCancel()}
                buttons={['cancel']}
                onEventSelect={onEventSelect}
                data={events}
                invalid={[]}
                showEventCount={true}
                showOuterDays={true}
                eventsText="slots"
                eventText="slot"
                min={now}
                noEventsText={loading ? "Loading bookings..." : "No bookings available"}
                // marked={[
                //     { d: 'w0', color: '#ffc400' },
                //     { d: '12/24', color: '#ffee00' },
                //     { d: '12/25', color: 'red' },
                //     // { d: new Date(now.getFullYear(), now.getMonth() + 1, 4) },
                //     { start: new Date(now.getFullYear(), now.getMonth() + 1, 15), end: new Date(now.getFullYear(), now.getMonth() + 1, 18), color: '#f4511e' }
                // ]}
                view={{
                    calendar: {
                        type: 'month',
                        size: 1
                        // labels: true
                    },
                    eventList: {type: 'month', scrollable: true}
                }}
            />
        </Page>
    )
};

const Page = styled.div`
  min-height: 100vh;
`;

export default TimeSlotSelector;
